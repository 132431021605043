import * as Types from "./types";

export const SetLabelArr = (data) => ({
  type: Types.SET_LABEL_ARR,
  payload: data,
});

export const SetLoading = (data) => ({
  type: Types.SET_LOADING,
  payload: data,
});

export const BackDropLoading = (data) => ({
  type: Types.BACK_DROP_LOADING,
  payload: data,
});

export const SetLabelRespData = (data) => ({
  type: Types.SET_LABEL_RESP_DATA,
  payload: data,
});
